<!--预约订单配置-->
<template>
  <div class="shopSetting">
    <div class="line">
      <span class="title">最少预约时间：</span>
      <div class="list">
        <div class="item" v-for="item in serviceVoList" :key="item.serviceId">
          <span class="itemTitle">服务内容：{{ item.serviceName }}</span>
          <el-select class="selectLength" v-model="item.advanceMinHour">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">
        <el-popover placement="top-start" trigger="click">
          <span
            >请确保门店空调设备已加装空调伴侣以及门店开启提前开空调的功能</span
          >
          <i
            class="el-icon-question"
            slot="reference"
            style="margin-right: 0.1rem"
          ></i>
        </el-popover>
        <span> 提前服务空调设置： </span>
      </span>
      <div class="list">
        <div class="item">
          <span class="itemTitle">温度：</span>
          <el-input
            class="selectLength"
            placeholder="温度"
            v-model="shopAirConfigVo.tempSet"
          >
            <template slot="append">°c</template>
          </el-input>
        </div>
        <div class="item">
          <span class="itemTitle">模式：</span>
          <div class="typeList">
            <div
              class="type"
              v-for="item in modeList"
              :key="item.value"
              :class="
                shopAirConfigVo.runModel == item.value ? 'type-active' : ''
              "
              @click="goSelect(1, item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="item">
          <span class="itemTitle">风速：</span>
          <div class="typeList">
            <div
              class="type"
              v-for="item in speedList"
              :key="item.value"
              :class="
                shopAirConfigVo.fanSpeed == item.value ? 'type-active' : ''
              "
              @click="goSelect(2, item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">保洁时段预约设置：</span>
      <div class="list">
        <div class="item">
          <span class="itemTitle">未完成保洁的位置时段：</span>
          <el-select
            class="selectLength type2"
            v-model="cleanConfig.cleanEnable"
          >
            <el-option
              v-for="item in preList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span class="itemTitle">预约</span>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">无保洁时段设置：</span>
      <div class="list">
        <div class="item item2">
          <el-switch v-model="cleanConfig.noCleanEnable" active-color="#ef9807">
          </el-switch>
          <span class="itemTitle ml-20"
            >开启后，用户在设置时段内下单会做弹框提醒</span
          >
        </div>
        <div class="item">
          <el-time-select
            placeholder="起始时间"
            v-model="cleanConfig.startTime"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
            }"
          >
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="cleanConfig.endTime"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              minTime: cleanConfig.startTime,
            }"
          >
          </el-time-select>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">续单折扣设置：</span>
      <div class="list">
        <div class="item item2">
          <el-switch
            v-model="cleanConfig.discountEnable"
            active-color="#ef9807"
          >
          </el-switch>
        </div>
        <div class="item">
          <span class="itemTitle">可续单时长在15分钟到60分钟内享受</span>
          <el-input
            class="selectLength type2"
            v-model="cleanConfig.discount"
            oninput="value=value.replace(/\D|^0/g, '')"
            @input="goLimitValue"
            type="number"
            :max="10"
            :min="1"
          >
          </el-input>
          <span class="itemTitle">折</span>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">快捷时间选择设置：</span>
      <div class="list">
        <div class="item" v-for="item in serviceVoList" :key="item.serviceId">
          <span class="itemTitle">服务内容：{{ item.serviceName }}</span>
          <el-select v-model="item.data1" class="selectLength" clearable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="item.data2" class="selectLength" clearable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="item.data3" class="selectLength" clearable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="item.data4" class="selectLength" clearable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">是否开启保洁设置：</span>
      <div class="list">
        <div class="item" v-for="item in serviceVoList" :key="item.serviceId">
          <span class="itemTitle">服务内容：{{ item.serviceName }}</span>
          <el-switch
            v-model="item.serviceCleanEnable"
            active-color="#EF9807"
            inactive-color="#909399"
          >
          </el-switch>
        </div>
      </div>
    </div>
    <div class="line">
      <span class="title">支付成功跳转商城导购：</span>
      <div class="list">
        <div class="item item2">
          <el-switch
            v-model="orderLinkEnable"
            active-color="#ef9807"
          ></el-switch>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button class="saveBtn" type="primary" @click="goSave()"
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
import { getOrderConfig, goSaveOrderConfig } from "@/api/shop/shopInfo.js";
export default {
  data() {
    return {
      shopId: "",
      serviceVoList: [],
      serviceTemplateType:1,//4仓储类
      shopAirConfigVo: {
        configId: "",
        fanSpeed: "Level1Wind",
        runModel: "Cool",
        tempSet: 26,
      },
      options: [],
      modeList: [
        {
          value: "Cool",
          label: "制冷",
        },
        {
          value: "Heat",
          label: "制热",
        },
        {
          value: "Dry",
          label: "除湿",
        },
        {
          value: "Fan",
          label: "送风",
        },
      ],
      speedList: [
        {
          value: "Level1Wind",
          label: "低",
        },
        {
          value: "Level2Wind",
          label: "中",
        },
        {
          value: "Level3Wind",
          label: "高",
        },
        {
          value: "AutoWind",
          label: "自动",
        },
      ],
      preList: [
        {
          value: false,
          label: "不允许",
        },
        {
          value: true,
          label: "允许",
        },
      ],
      cleanConfig: {
        cleanEnable: true,
        discount: 0,
        discountEnable: true,
        endTime: "",
        noCleanEnable: true,
        startTime: "",
      },
      orderLinkEnable: false,
      options: [],
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {

    this.getOrderConfig();
  },
  methods: {
    // 生成时间选择
   
    goLimitValue() {
      if (this.cleanConfig.discount && this.cleanConfig.discount <= 0) {
        this.cleanConfig.discount = 1;
      }
      if (this.cleanConfig.discount > 10) {
        this.cleanConfig.discount = 10;
      }
    },
    // 保存
    goSave() {
      let isCanSave = this.serviceVoList.every((item) => {
        return (
          (!item.data1 && !item.data2 && !item.data3 && !item.data4) ||
          (item.data1 >= item.advanceMinHour &&
            item.data2 >= item.advanceMinHour &&
            item.data3 >= item.advanceMinHour &&
            item.data4 >= item.advanceMinHour)
        );
      });
      if (isCanSave) {
        let params = {
          shopId: this.shopId,
          shopAirConfigVo: this.shopAirConfigVo,
          serviceVoList: this.serviceVoList,
          cleanConfig: this.cleanConfig,
          orderLinkEnable: this.orderLinkEnable,
        };
        goSaveOrderConfig(params).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: "保存成功!",
            });
            this.getOrderConfig();
          }
        });
      } else {
        this.$message.error({
          message: "存在快捷时间时，必须都填写快捷时间且大于最小预约时间",
        });
      }
      console.log(1, isCanSave);
    },
    // 选择
    goSelect(type, value) {
      if (type == 1) {
        this.shopAirConfigVo.runModel = value;
      } else {
        this.shopAirConfigVo.fanSpeed = value;
      }
    },
    // 生成时间选择
    goInitHourList(serviceTemplateType) {
      let str='小时'
      if(serviceTemplateType==4){
        str='个月'
      }
      for (let i = 1; i <= 24; i++) {
        let obj = {
          value: i,
          label: i + str,
        };
        this.options.push(obj);
      }
    },
    // 查询配置
    getOrderConfig() {
      let params = {
        shopId: this.shopId,
      };
      getOrderConfig(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceVoList = res.data.serviceVoList;
          this.serviceTemplateType=res.data.serviceVoList[0].serviceTemplateType
          this.goInitHourList(this.serviceTemplateType);
          if (res.data.shopAirConfigVo) {
            this.shopAirConfigVo = res.data.shopAirConfigVo;
          }
          this.cleanConfig = res.data.cleanConfig;
          this.orderLinkEnable = res.data.orderLinkEnable;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopSetting {
  padding: 20px;
  box-sizing: border-box;
  .line {
    display: flex;
    margin-bottom: 30px;
    .title {
      width: 180px;
      text-align: right;
      font-weight: 500;
      padding: 9px;
    }
    .list {
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.2rem;
        .itemTitle {
          width: fit-content;
          margin-right: 0.2rem;
          text-align: left;
        }
        .ml-20 {
          margin-left: 0.2rem;
        }
        .selectLength {
          width: 2rem;
          margin-right: 0.2rem;
        }
        .type2 {
          width: 120px;
          margin-right: 0.2rem;
        }

        .typeList {
          display: flex;
          .type {
            width: 2rem;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            text-align: center;
            margin-right: 20px;
            cursor: pointer;
          }
          .type-active {
            color: #fff;
            border: 1px solid #ef9807;
            background: #ef9807;
          }
        }
      }
      .item2 {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    .saveBtn {
      width: 2rem;
    }
  }
}
</style>